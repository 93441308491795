/* eslint-disable jsx-a11y/label-has-associated-control */
import { ApolloError, useQuery } from '@graphcommerce/graphql'
import { ApolloCustomerErrorSnackbar } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorSnackbar'
import { SignUpConfirmDocument } from '@graphcommerce/magento-customer/components/SignUpForm/SignUpConfirm.gql'
import { graphqlErrorByCategory } from '@graphcommerce/magento-graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { FormActions } from '@graphcommerce/next-ui'
import { useFormGqlMutation, useFormPersist } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Alert, Checkbox, MenuItem } from '@mui/material'
import { AxiosError } from 'axios'
import { useContext, useState } from 'react'
import { handleSubscribeToNewsletter } from '../../BuilderIO/KlaviyoNewsletterApiCall'
import { StandardButton } from '../../Button/ButtonStyles'
import { StandardDropdown } from '../../Dropdown/StandardDropdown'
import { fbqCompleteRegistration } from '../../FacebookPixel/events/fbqCompleteRegistration'
import { Message } from '../../Message'
import { globalContext } from '../../NextUi/globalContext'
import { ErrorPrompt } from '../../Prompt/ErrorPrompt'
import { StandardTextField } from '../../TextInput/TextFieldStyles'
import { NameFields } from '../NameFields/NameFields'
import { SignUpDocument, SignUpMutation, SignUpMutationVariables } from './SignUp.gql'

type SignUpFormProps = { email: string }

const requireEmailValidation = process.env.CUSTOMER_REQUIRE_EMAIL_CONFIRMATION === '1'

export const industryTypeOptions = [
  { value: 'personal', label: 'Personal Use' },
  { value: 'designer', label: 'Designer' },
  { value: 'bar_restaurant', label: 'Bar + Restaurant' },
  { value: 'cafe_bakery', label: 'Cafe / Bakery' },
  { value: 'retail', label: 'Retail' },
  { value: 'hotel', label: 'Hotel' },
]

export function SignUpForm(props: SignUpFormProps) {
  const { email } = props

  const storeConfig = useQuery(StoreConfigDocument).data?.storeConfig

  const { drawer, setRedirectAfterSignIn } = useContext(globalContext)

  const Mutation = requireEmailValidation ? SignUpConfirmDocument : SignUpDocument
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false)

  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false)
  const [customError, setCustomError] = useState<Error>()

  const handleError = (e: Error) => {
    if ((e as AxiosError)?.response?.status === 409) {
      setCustomError(new Error('You are already subscribed to our newsletter.'))
    } else {
      setCustomError(
        new Error(
          'We encountered an error while processing your subscription. We will get this issue fixed ASAP.',
        ),
      )
    }
    console.log(e)
  }
  const [susbcribeToNewsletter, setSubscribeToNewsletter] = useState<boolean>(true)

  const form = useFormGqlMutation<
    SignUpMutation,
    SignUpMutationVariables & { confirmPassword?: string }
  >(
    Mutation,
    {
      defaultValues: { email, prefix: '', companyIndustry: 'personal' },
      onBeforeSubmit: (values) => ({ ...values, email }),
      onComplete: async () => {
        fbqCompleteRegistration()
        if (susbcribeToNewsletter) {
          await handleSubscribeToNewsletter(
            setIsSubscribing,
            setDisplayPrompt,
            handleError,
            email,
            form.getValues().firstname?.toString() ?? '',
            form.getValues().lastname?.toString() ?? '',
            [form?.getValues().companyIndustry?.toString() ?? ''],
            true,
            { subscribed_from_account_creation: true },
          )
        }
      },
    },
    { errorPolicy: 'all' },
  )

  const { register, handleSubmit, required, watch, formState, error } = form
  const [remainingError, inputError] = graphqlErrorByCategory({ category: 'graphql-input', error })

  const submitHandler = handleSubmit(() => {
    if (drawer.id === 'auth' && typeof drawer.params?.redirectAfterSignIn === 'string') {
      setRedirectAfterSignIn(drawer.params.redirectAfterSignIn)
    }
  })
  const watchPassword = watch('password')

  useFormPersist({ form, name: 'SignUp', exclude: ['password', 'confirmPassword'] })

  if (requireEmailValidation && form.formState.isSubmitSuccessful) {
    return (
      <Alert>
        <Trans id='Please check your inbox to validate your email ({email})' values={{ email }} />
      </Alert>
    )
  }

  const menuItems = industryTypeOptions.map((option) => (
    <MenuItem value={option.value}>{option.label}</MenuItem>
  ))

  return (
    <form onSubmit={submitHandler} noValidate>
      <div className='mt-6 grid min-w-[260px] items-center justify-center gap-6'>
        <StandardTextField
          label={<Trans id='Password' />}
          variant='outlined'
          color='primary'
          size='small'
          key='password'
          type='password'
          error={!!formState.errors.password || !!inputError}
          autoComplete='current-password'
          id='current-password'
          required={required.password}
          {...register('password', {
            required: required.password,
            minLength: {
              value: Number(storeConfig?.minimum_password_length ?? 8),
              message: i18n._(/* i18n */ 'Password must have at least 8 characters'),
            },
          })}
          className='min-w-[260px]'
          sx={{
            minWidth: '260px',
          }}
        />
        <StandardTextField
          label={<Trans id='Re-enter password' />}
          variant='outlined'
          color='primary'
          size='small'
          type='password'
          error={!!formState.errors.confirmPassword}
          autoComplete='new-password'
          required
          {...register('confirmPassword', {
            required: true,
            validate: (value) =>
              value === watchPassword || i18n._(/* i18n */ "Passwords don't match"),
          })}
          helperText={formState.errors.confirmPassword?.message}
          disabled={formState.isSubmitting}
          className='min-w-[260px]'
          sx={{
            minWidth: '260px',
          }}
        />
        <StandardTextField
          label={<Trans id='Company Name' />}
          variant='outlined'
          color='primary'
          size='small'
          type='text'
          error={!!formState.errors.firstname}
          required
          {...register('companyName', {
            required: true,
          })}
          className='min-w-[260px]'
          sx={{
            minWidth: '260px',
          }}
        />
      </div>
      <NameFields form={form} required prefix columnDisplay className='my-6' />
      <StandardDropdown
        value={watch('companyIndustry')?.toString() || ''}
        className='w-[260px] max-w-[260px]'
        {...register('companyIndustry')}
        onChange={(option) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (option?.target?.value)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            form.setValue('companyIndustry', option?.target?.value?.toString())
        }}
        label='Industry Type'
        options={menuItems}
        sx={{
          width: '260px',
          minWidth: '260px',
        }}
      />
      <div className='my-6 flex w-[260px] flex-nowrap items-start justify-between gap-x-2'>
        <Checkbox
          onClick={() => {
            setSubscribeToNewsletter(!susbcribeToNewsletter)
          }}
          checked={susbcribeToNewsletter}
          id='subscribeToNewsletter'
          sx={{ padding: 0 }}
        />
        <label htmlFor='subscribeToNewsletter' className='text-[12px]'>
          {i18n._(
            "Sign up to receive coupons, advice and inspiration. You can unsubscribe from email marketing via the 'Unsubscribe' link in the emails.",
          )}
        </label>
      </div>
      {inputError && <Message content={inputError.message} />}

      <FormActions>
        <StandardButton
          type='submit'
          id='create-account'
          loading={formState.isSubmitting}
          className='w-[260px] max-w-[260px] bg-material-ui-blue uppercase'
          variant='contained'
          size='small'
        >
          <Trans id='Create account!' />
        </StandardButton>
      </FormActions>
      <ErrorPrompt error={error || (customError as ApolloError)} />
    </form>
  )
}
